import React from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  Select,
  Button,
} from "@chakra-ui/react";

const RolePermissionManger= () => {
  return (
    <Box width="80%" margin="auto" mt={5}>
      <Select placeholder="Select Role" mb={4}>
        <option value="admin">Admin</option>
        <option value="manager">Manager</option>
        <option value="user">User</option>
      </Select>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Action</Th>
            <Th isNumeric>Add</Th>
            <Th isNumeric>Edit</Th>
            <Th isNumeric>Delete</Th>
            <Th isNumeric>View</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>Add Project</Td>
            <Td isNumeric><Checkbox /></Td>
            <Td isNumeric><Checkbox /></Td>
            <Td isNumeric><Checkbox /></Td>
            <Td isNumeric><Checkbox /></Td>
          </Tr>
          <Tr>
            <Td>Add Block</Td>
            <Td isNumeric><Checkbox /></Td>
            <Td isNumeric><Checkbox /></Td>
            <Td isNumeric><Checkbox /></Td>
            <Td isNumeric><Checkbox /></Td>
          </Tr>
          <Tr>
            <Td>Add Plot</Td>
            <Td isNumeric><Checkbox /></Td>
            <Td isNumeric><Checkbox /></Td>
            <Td isNumeric><Checkbox /></Td>
            <Td isNumeric><Checkbox /></Td>
          </Tr>
          <Tr>
            <Td>Master Input</Td>
            <Td isNumeric><Checkbox /></Td>
            <Td isNumeric><Checkbox /></Td>
            <Td isNumeric><Checkbox /></Td>
            <Td isNumeric><Checkbox /></Td>
          </Tr>
          <Tr>
            <Td>Booking Status</Td>
            <Td isNumeric><Checkbox /></Td>
            <Td isNumeric><Checkbox /></Td>
            <Td isNumeric><Checkbox /></Td>
            <Td isNumeric><Checkbox /></Td>
          </Tr>
          <Tr>
            <Td>On-Holds Plots</Td>
            <Td isNumeric><Checkbox /></Td>
            <Td isNumeric><Checkbox /></Td>
            <Td isNumeric><Checkbox /></Td>
            <Td isNumeric><Checkbox /></Td>
          </Tr>
          <Tr>
            <Td>Payment Transaction-To Approve</Td>
            <Td isNumeric><Checkbox /></Td>
            <Td isNumeric><Checkbox /></Td>
            <Td isNumeric><Checkbox /></Td>
            <Td isNumeric><Checkbox /></Td>
          </Tr>
          <Tr>
            <Td>Payment Transaction-Add Payment</Td>
            <Td isNumeric><Checkbox /></Td>
            <Td isNumeric><Checkbox /></Td>
            <Td isNumeric><Checkbox /></Td>
            <Td isNumeric><Checkbox /></Td>
          </Tr>
          <Tr>
            <Td>Payment Transaction-Add Payment</Td>
            <Td isNumeric><Checkbox /></Td>
            <Td isNumeric><Checkbox /></Td>
            <Td isNumeric><Checkbox /></Td>
            <Td isNumeric><Checkbox /></Td>
          </Tr>
          <Tr>
            <Td>Payment Transaction-Add Payment</Td>
            <Td isNumeric><Checkbox /></Td>
            <Td isNumeric><Checkbox /></Td>
            <Td isNumeric><Checkbox /></Td>
            <Td isNumeric><Checkbox /></Td>
          </Tr>
      
        </Tbody>
      </Table>

      <Button mt={4} colorScheme="teal">
        Save
      </Button>
    </Box>
  );
};

export default RolePermissionManger;