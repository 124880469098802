import React from "react";
import { Center, Heading } from "@chakra-ui/react";
const Home = () => {
  return (
    <>
      <Center height={"80vh"}>
        <Heading>Welcome to Layout Management System </Heading>
      </Center>
    </>
  );
};

export default Home;
